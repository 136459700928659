/* global Foundation */
/* global ajax_object */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';
/**
 * Init foundation
 */
$(document).foundation();
let $selects = $('#terms'),
  offsetNews = 4,
  term;

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

$('.th-webinars').slick({
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

// Change learn more to Contact Us
function changeText($this, getBack) {
  let hoverText = $this.data('hover-text'),
    text = $this.data('text');
  if (getBack) {
    $this.text(hoverText);
  } else {
    $this.text(text);
  }
}
function loadNews(term, offset, select) {
  const data = {
    action: 'action_news_load',
    nonce: ajax_object.nonce,
    offset: offset,
    term: term,
    select: select,
  };

  $.ajax({
    url: ajax_object.url,
    type: 'post',
    dataType: 'json',
    data,
    // eslint-disable-next-line no-unused-vars
    success: function (response) {
      if (select) {
        $('.news-container').html(response.html);
      } else {
        $('.news-container').append(response.html);
      }
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}
// Ajax load Tech posts
let offsetTech = 4; // Початкове значення offsetTech
const postsPerPage = 4;

$(document).on('click', '#loadmore_tech', function (e) {
  e.preventDefault();
  loadTech(term, offsetTech);
});

$('.author-wrap').on('click', '.author-filter__name', function () {
  $('.author-filter__name').removeClass('current');
  $(this).addClass('current');
  term = $(this).data('value') === 'all' ? '' : $(this).data('value');
  $('.posts-container').empty();
  offsetTech = 0; //
  loadTech(term, offsetTech);
});

$('.author-wrap').on('click', '.all-name', function () {
  $('.author-filter__name').removeClass('current');
  $(this).addClass('current');
  term = '';
  $('.posts-container').empty();
  offsetTech = 4;
  loadTech(term, offsetTech);
});

function loadTech(term, offset) {
  const data = {
    action: 'action_tech_load',
    nonce: ajax_object.nonce,
    offset: offset,
    term: term,
  };

  $.ajax({
    url: ajax_object.url,
    type: 'post',
    dataType: 'json',
    data: data,
    success: function (response) {
      $('.posts-container').append(response.html);
      const numberOfPosts = $('.posts-container').find('.cell').length;
      if (numberOfPosts % postsPerPage !== 0 || response.html.trim() === '') {
        $('#loadmore_tech').hide();
      } else {
        $('#loadmore_tech').show();
      }
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}

// Ajax load search posts
function loadSearchPosts(search, offsetPosts) {
  const data = {
    action: 'action_search_load',
    nonce: ajax_object.nonce,
    offset_posts: offsetPosts,
    search: search,
  };

  $.ajax({
    url: ajax_object.url,
    type: 'post',
    dataType: 'json',
    data,
    success: function (response) {
      $('.search-page-container').append(response.html);
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}
// Ajax load posts
function loadPosts(offset) {
  const data = {
    action: 'action_post_load',
    nonce: ajax_object.nonce,
    offset: offset,
  };
  //
  // if ($('.posts-container').data('single')) {
  //   data.isSingle = true;
  // }

  $.ajax({
    url: ajax_object.url,
    type: 'post',
    dataType: 'json',
    data,
    // eslint-disable-next-line no-unused-vars
    success: function (response) {
      $('.posts-container').append(response.html);
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}

function loadPostsTech(offset) {
  const data = {
    action: 'action_post_load_tech_hub_single',
    nonce: ajax_object.nonce,
    offset: offset,
  };
  //
  // if ($('.posts-container').data('single')) {
  //   data.isSingle = true;
  // }

  $.ajax({
    url: ajax_object.url,
    type: 'post',
    dataType: 'json',
    data,
    // eslint-disable-next-line no-unused-vars
    success: function (response) {
      $('.posts-container').append(response.html);
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}
//Ajax load webinars
function loadWebinars(cat, page, isFilter) {
  const data = {
    action: 'action_webinars_load',
    nonce: ajax_object.nonce,
    paged: page,
    cat: cat,
    isFilter: isFilter,
  };

  $.ajax({
    url: ajax_object.url,
    type: 'post',
    dataType: 'json',
    data,
    // eslint-disable-next-line no-unused-vars
    success: function (response) {
      if (isFilter) {
        $('.webinar-container').html(response.html);
      } else {
        $('.webinar-container').append(response.html);
      }
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}
function loadNewsSingle(term, offset) {
  const data = {
    action: 'action_news_single',
    nonce: ajax_object.nonce,
    offset: offset,
    term: term,
  };

  $.ajax({
    url: ajax_object.url,
    type: 'post',
    dataType: 'json',
    data,
    // eslint-disable-next-line no-unused-vars
    success: function (response) {
      $('.single-news-container').append(response.html);
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}

function loadTaxonomies(postType, offset, taxonomy, termId, amount, showPosts) {
  const data = {
    action: 'action_taxonomy_load',
    nonce: ajax_object.nonce,
    postType: postType,
    offset: offset,
    taxonomy: taxonomy,
    termId: termId,
    amount: amount,
    showPosts: showPosts,
  };

  $.ajax({
    url: ajax_object.url,
    type: 'post',
    dataType: 'json',
    data,
    // eslint-disable-next-line no-unused-vars
    success: function (response) {
      $('.post-list').append(response.html);
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}
const tabContent = function (el) {
  let contentDiv = el
    .closest('.cell')
    .siblings('.cell')
    .find('.entry__content');
  let headers = contentDiv.find($('h1,h2,h3,h4,h5,h6,strong'));
  headers.each(function () {
    let idText = $(this)
      .text()
      .toLowerCase()
      .replace(/[^a-z0-9\s]/gi, '')
      .replace(/[_\s]/g, '-');
    $(this).attr('id', idText);
  });
  // console.log($('*:contains(' + el.find('a').text() + ')'));
  // let idText = .text().toLowerCase().replace(/ /g, '_');
  $(el.find('a')).each(function () {
    let idText = $(this)
      .text()
      .toLowerCase()
      .replace(/[^a-z0-9\s]/gi, '')
      .replace(/[_\s]/g, '-');
    $(this).attr('href', '#' + idText);
  });
};
const skipNavButton = function (el) {
  $(el).on('click', function (e) {
    e.preventDefault();
    $('a:not(header a)')[0].focus();
  });
};

const accordionSinglePosts = function (el) {
  el.find('.tab-head').on('click', function () {
    $(this).toggleClass('is-active');
    $(this).siblings('.tab-content').slideToggle();
  });
};

/**
 * Scripts which runs after DOM load
 */
function clickCard(arr) {
  $(arr).each(function (idx, el) {
    $(el).on('click', function () {
      let link = $(this).find('a');
      let href = $(link).attr('href');
      if ($(link).data('permalink')) {
        window.location.href = `${href}`;
      }
    });
  });
}

$(document).on('ready', function () {
  $(document).on('click', '.author-filter__title', function () {
    $('.author-filter__box').toggleClass('hide-list-authors');
    $(this).next('.author-filter').slideToggle();
  });

  // Review page
  if ($('.review-page').length) {
    let form = $('.review-page-popup-form'),
      social = $('.review-page-popup-socials'),
      socialItems = social.find('.socials-wrapper'),
      main = $('.review-page'),
      stars = main.find('.stars-wrapper'),
      close = $('.close-button');
    close.on('click', function () {
      $(this).closest('section').hide();
    });
    stars.on('click', function () {
      stars.removeClass('is-active');
      $(this).addClass('is-active').prevAll().addClass('is-active');
      if ($(this).data('number') > 3) {
        social.show();
      } else {
        form.show();
      }
    });
    stars.mouseover(function () {
      $(this).addClass('is-active').prevAll().addClass('is-active');
      $(this).nextAll().removeClass('is-active');
    });

    if (socialItems.hasClass('a-lot')) {
      socialItems.on('click', function () {
        $(this).toggleClass('is-active');
        $(this).siblings('.socials-wrapper--popup').slideToggle();
        // $(this).closest('.socials-wrapper--popup').toggle();
      });
    }

    $('.close-awesome').on('click', (e) =>
      $(e.currentTarget).parent().slideUp()
    );
  }

  if ($('#tax-load-more').length) {
    let taxOffset = $('#tax-load-more').data('show');
    $('#tax-load-more').on('click', function (e) {
      e.preventDefault();
      let postType = $(this).data('post-type'),
        show = $(this).data('show'),
        termId = $(this).data('tax-term-id'),
        tax = $(this).data('tax'),
        amount = $(this).data('amount');
      console.log(taxOffset);
      loadTaxonomies(postType, taxOffset, tax, termId, amount, show);
      taxOffset += show;
      if (taxOffset >= amount) {
        $(this).hide();
      }
    });
  }

  if ($('.tab-wrapper').length) {
    accordionSinglePosts($('.tab-wrapper'));
  }

  if ($('.posts-accordion__wrapper').length) {
    accordionSinglePosts($('.posts-accordion__wrapper'));
  }

  if ($('.filter-block--new').length) {
    $('.filter-block--new')
      .find('.news-categories')
      .on('click', function () {
        $('.news-categories').removeClass('current');
        $(this).addClass('current');
        term = $(this).data('value') === 'all' ? '' : $(this).data('value');
        loadNews(term, offsetNews, true);
      });
  }

  if ($('.mobile-head').length) {
    $('.mobile-head').on('click', function () {
      $(this).toggleClass('is-active');
      $(this).siblings().slideToggle();
    });
  }

  tabContent($('.anchors-wrapper'));

  skipNavButton('.skip-nav-button');

  let clickableCards = $('.health-checks__bot__inner').find('.page-card');
  clickCard(clickableCards);
  //Single event from after submit
  if ($('body').hasClass('single-tribe_events')) {
    $(document).on('gform_confirmation_loaded', function () {
      $('.event-calendar-wrapper').show();
    });
  }
  // Partners logo slider
  let btnArrow = $('.strategy-partners__card__arrows').find('.btn');
  btnArrow.on('click', function (e) {
    e.preventDefault();
    // let i = 0;
    let images = $('.card-image--slider img[data-partner-thumb]');

    if ($(this).data('arrow') == 'left') {
      for (let i = 0; i < images.length; i++) {
        if ($(images[i]).hasClass('image-first')) {
          $(images[i]).removeClass('image-first');
          if (i == 0) {
            i = images.length - 1;
            $(images[i]).addClass('image-first');
            // $(images[i - (images.length - 1)]).addClass('image-first');
          } else {
            $(images[--i]).addClass('image-first');
          }
        }
      }
    }
    if ($(this).data('arrow') == 'right') {
      for (let i = 0; i < images.length; i++) {
        if ($(images[i]).hasClass('image-first')) {
          $(images[i]).removeClass('image-first');
          if (i == images.length - 1) {
            i = 0;
            $(images[i]).addClass('image-first');
            // $(images[i - (images.length - 1)]).addClass('image-first');
          } else {
            $(images[++i]).addClass('image-first');
          }
        }
      }
    }
  });

  if ($('.btn-change-text').length) {
    $('.btn-change-text').hover(function (e) {
      let $this = $(this);
      if (e.type == 'mouseenter') {
        setTimeout(() => changeText($this, true), 320);
      } else {
        setTimeout(() => changeText($this, false), 320);
      }
    });
  }

  $('#how-can-we-help').on('click', function (e) {
    e.preventDefault();
    $(this)
      .prev('.cta__red-cta__form')
      .find('.how-can-we-help_wrapper')
      .slideToggle();
  });

  // Career opportunities links for mobile
  if (window.innerWidth < 640) {
    $('.career-posts__card').each(function () {
      let link = $(this).find('.career-link').attr('href');
      $(this).wrap(`<a href="${link}" class="tyest"></a>`);
    });
  }
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  $('.menu-icon-btn').on('click', function () {
    $(this).find('.close-icon').toggle();
    $(this).find('.burger-icon').toggle();
    $('#main-menu').css('display', 'block');
    $('.menu-header-menu-container').slideToggle(600);
  });
  // Iterate over each select element
  $('.custom-select').each(function () {
    // Cache the number of options
    let $this = $(this),
      numberOfOptions = $(this).children('option').length;

    // Hides the select element
    $this.addClass('s-hidden');

    // Wrap the select element in a div
    $this.wrap('<div class="select"></div>');

    // Insert a styled div to sit over the top of the hidden select element
    $this.after('<div class="styledSelect"></div>');

    // Cache the styled div
    let $styledSelect = $this.next('div.styledSelect');

    // Show the first select option in the styled div
    // $styledSelect.text($this.children('option').eq(0).text());
    $styledSelect.append(
      `<span>${$this.children('option').eq(0).text()}</span>`
    );

    // Insert an unordered list after the styled div and also cache the list
    let $list = $('<ul />', {
      class: 'options',
    }).insertAfter($styledSelect);

    // Insert a list item into the unordered list for each select option
    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        'data-option': $this.children('option').eq(i).val(),
      }).appendTo($list);
    }

    $($list)
      .find('li')
      .map(function (indx, el) {
        if ($(el).data('option') == 'all') {
          $(el).text('All');
        }
      });

    // Cache the list items
    let $listItems = $list.children('li');

    $list.hide();
    // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        $list.slideDown('slow');
      } else {
        $list.slideUp();
      }
    });

    // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
    // Updates the select element to have the value of the equivalent option
    $listItems.click(function (e) {
      e.stopPropagation();
      $styledSelect.find('span').text(function () {
        if ($(e.target).data('option') === 'all') {
          return 'Products & Services';
        } else {
          return e.target.innerText;
        }
      });
      $styledSelect.removeClass('active');
      $list.slideUp();
      $this.val($(this).attr('rel'));
      loadWebinars($(this).data('option'), '', true);
    });

    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.slideUp();
    });
  });

  // Info section delete divider if above black tab section
  let aboveSection = $('.info-box').prev();
  if ($(aboveSection[0]).is('.bg-color--dark, .tabs-section--dark')) {
    $('.info-box').find('.info-box__title').css('borderTop', 'none');
  }
  // Anchor tabs
  $('.anchors-tabs__title').on('mouseenter', function () {
    let elArray = $('.anchors-tabs__title');
    elArray.removeClass('active-tab');
    $(this).addClass('active-tab');
  });
  $('.anchors-tabs__link').on('click', function () {
    let anchor = $(this).attr('href');
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $(anchor).offset().top - 70,
        },
        1500
      );
  });

  $('.tabs-title').on('mouseenter', function () {
    let elArray = $('.tabs-title');
    let tabsContent = $('.tabs-panel');
    tabsContent.removeClass('is-active');
    tabsContent.attr('aria-hidden', true);
    elArray.removeClass('is-active');
    elArray.children('a').attr({
      tabindex: -1,
      ariaSelected: false,
    });
    $(this).addClass('is-active');
    if ($(this).hasClass('is-active')) {
      $(this).children('a').attr('aria-selected', true);
      $(this).children('a').attr('tabindex', 0);
      $(`.tabs-panel[id=${$(this).children('a').attr('aria-controls')}]`)
        .removeAttr('aria-hidden')
        .addClass('is-active');
    }
  });
  // let mobHeaderHeight = $('#header').innerHeight() + 82 + 'px';
  setTimeout(function () {
    let mobHeaderHeight = $('#header').outerHeight() + 82;
    document.documentElement.style.setProperty(
      '--vhmob',
      `${mobHeaderHeight}px`
    );
  }, 1000);

  // Menu dropdown animate
  if (window.innerWidth < 1025) {
    $('.menu-item-has-children.first-row-elements')
      .find('.menu-svg--mob')
      .on('click', function () {
        let $submenu = $(this).closest('li').find('.mega-menu-wrapper');
        if ($submenu.hasClass('mob-active')) {
          $('body').css('position', 'static');
          $submenu.slideUp(600);
          $submenu.removeClass('mob-active');
        } else {
          $('body').css('position', 'fixed');
          $('.mega-menu-wrapper').removeClass('mob-active').slideUp(600);
          $submenu.slideDown(600);
          $submenu.addClass('mob-active');
        }
      });
  }
  // Arrow scroll up to top
  if ($('.arrow-up').length) {
    $('.arrow-up').click(function (e) {
      e.preventDefault();
      $('html, body').stop().animate(
        {
          scrollTop: 0,
        },
        2000
      );
    });
  }

  // let counterSerach = '';
  let amoutSerchPosts = $('#search-page__btn').data('amount');
  let offsetPosts = 6;
  $('#search-page__btn').on('click', function (e) {
    e.preventDefault();
    let search = $(this).data('search');
    loadSearchPosts(search, offsetPosts);
    offsetPosts += 2;
    if (offsetPosts >= amoutSerchPosts) {
      $(this).hide();
    }
  });

  let offsetBlog = 5;
  $(document).on('click', '#loadmore', function (e) {
    e.preventDefault();
    // let $cat = $('.select-wrapper').find('li.li-options').data('val')
    //   ? $('.select-wrapper').find('li.li-options').data('val')
    //   : 'categories';
    // let $cat = $(this).data('cat');
    // let $cat2 = $('#form-select').find('.select-label').text();
    // let amount = $('#loadmore').data('amount');
    let amount = $(this).data('amount');
    loadPosts(offsetBlog);
    offsetBlog += 2;
    if (amount <= offsetBlog) {
      setTimeout(function () {
        $('#loadmore').hide();
      }, 1000);
    }
  });

  let offsetSingleTech = 3;
  $(document).on('click', '#loadmore_tech_single', function (e) {
    e.preventDefault();
    let amount = $(this).data('amount-tech-single');
    loadPostsTech(offsetSingleTech);
    offsetSingleTech += 2;
    console.log(offsetSingleTech);
    if (amount <= offsetSingleTech) {
      setTimeout(function () {
        $('#loadmore_tech_single').hide();
      }, 1200);
    }
  });

  // custom select
  $('#first-option').hide();
  function create_custom_select($selects) {
    $selects.each(function () {
      let $this = $(this);

      // create custom select markup
      $this.wrap('<div class="select-wrapper"></div>');
      let $wrapper = $this.closest('.select-wrapper');
      $wrapper.append('<div class="select-label" ></div>');
      $wrapper.append('<div class="chosen-item">');
      $wrapper.append('<ul>');

      // populate ul with all select items
      let $list = $wrapper.find('ul');
      $this.find('option').each(function (index, el) {
        let disabled = $(el).attr('disabled') ? 'disabled' : '';
        $list.append(
          $(
            '<li class="li-options" data-val="' +
              $(el).val() +
              '"' +
              disabled +
              '>' +
              $(el).text() +
              '</li>'
          )
        );
      });
    });
  }

  create_custom_select($selects);

  let curText =
    $('#form-select .current').text().length != 0
      ? $('#form-select .current').text()
      : 'All';
  $('#form-select .select-label').text(curText);

  let firstOption = $('li[data-val="news"]');
  firstOption.hide();

  // choose select option on custom select option click
  $('.select-wrapper ul li').on('click', function () {
    let $this = $(this);
    // let selectedLabel = $this.closest('.select-label');
    let $wrapper = $this.closest('.select-wrapper');
    let $chosen_item = $wrapper.find('.chosen-item');
    let val = $this.data('val');
    let text = $this.text();
    if ($this.attr('disabled')) {
      return false;
    }
    const delFirstOption = function () {
      if ($this.on('click')) {
        firstOption.show();
      }
    };

    setTimeout(delFirstOption, 1000);

    $wrapper.addClass('not-empty');
    // selectedLabel.addClass(val);
    $chosen_item.text(text);
    $this.closest('ul').slideUp();
    $wrapper.find('option').attr('selected', false);
    $wrapper.find("option[value='" + val + "']").attr('selected', true);

    // $('#form-select').submit();
    if (val === 'news') {
      term = '';
    } else {
      term = val;
    }
    loadNews(term, offsetNews, true);
  });
  // show/hide custom select options
  $(document).on('click', '.chosen-item, .select-label', function () {
    let $this = $(this);
    $this.nextAll('ul').slideToggle();
  });

  // close custom select on click outside it
  $(document).on('click', function (e) {
    let $target = $(e.target);
    if (!$target.closest('.select-wrapper').length) {
      $('.select-wrapper').removeClass('open').find('ul').slideUp();
    } else {
      $target.closest('.select-wrapper').toggleClass('open');
    }
  });

  $('#loadmore_news').on('click', function (e) {
    e.preventDefault();
    // let $cat = $('.select-wrapper').find('li.li-options').data('val')
    //   ? $('.select-wrapper').find('li.li-options').data('val')
    //   : 'categories';
    // let $cat = $(this).data('cat');
    // let $cat2 = $('#form-select').find('.select-label').text();
    let amount = $(this).data('amount');
    loadNews(term, offsetNews, false);
    offsetNews += 2;
    if (amount <= offsetBlog) {
      setTimeout(function () {
        $('#loadmore').hide();
      }, 1000);
    }
  });

  let newsCountSingle = $('#loadmore-single-news').data('amount'),
    offsetNewsSingle = 3;
  $('#loadmore-single-news').on('click', function (e) {
    e.preventDefault();
    let term = $(this).data('term');
    loadNewsSingle(term, offsetNewsSingle);
    offsetNewsSingle += 3;
    if (offsetNewsSingle >= newsCountSingle) {
      $(this).hide();
    }
  });

  let curPage = 1;
  let curentAmount = $('.webinar-container').find(
    '.section-webinars__video'
  ).length;
  let amount = $('#loadmore-web').data('amount') - curentAmount;
  $(document).on('click', '#loadmore-web', function (e) {
    e.preventDefault();
    amount -= $(this).data('per-page');
    if (amount <= 0) {
      $(this).hide('slow');
    }
    loadWebinars($(this).data('per-page'), ++curPage, false);
  });

  // Ajax load releases
  function categoryLoad(offset, cat) {
    const data = {
      action: 'action_category_load',
      nonce: ajax_object.nonce,
      offset: offset,
      cat: cat,
    };

    $.ajax({
      url: ajax_object.url,
      type: 'post',
      dataType: 'json',
      data,
      success: function (response) {
        $('.category-page__container').find('.grid-x').append(response.html);
      },
      error: function (e) {
        console.log('error', e);
      },
    });
  }

  let offset = 6;
  $('#category-page__load-more').on('click', function (e) {
    e.preventDefault();
    let amount = $('#category-page__load-more').data('amount');
    let cat = $('#category-page__load-more').data('cat');
    categoryLoad(offset, cat);
    offset += 2;
    if (amount <= offset) {
      setTimeout(function () {
        $('#category-page__load-more').hide();
      }, 1000);
    }
  });

  //Ajax blog-component
  function blogComponent(cat, offset) {
    const data = {
      action: 'action_blog_component',
      nonce: ajax_object.nonce,
      cat: cat,
      offset: offset,
    };

    $.ajax({
      url: ajax_object.url,
      type: 'post',
      dataType: 'json',
      data,
      // eslint-disable-next-line no-unused-vars
      success: function (response) {
        $('.blog-component__inner').append(response.html);
      },
      error: function (e) {
        console.log('error', e);
      },
    });
  }

  let numPosts = $('.blog-component__load-more').find('.btn').data('num-posts');
  $('.blog-component__load-more')
    .find('.btn')
    .on('click', function (e) {
      e.preventDefault();
      let cat = $(this).data('cat-id');
      blogComponent(cat, numPosts);
      numPosts += 2;
      if ($(this).data('count') <= numPosts) {
        $(this).parent().hide();
      }
    });

  /**
   * Map popups
   */
  $(document).on('click', '.map-markers', function () {
    let popDat = $(this).data('val');
    if (window.innerWidth > 1024) {
      // $(this).find('.marker-popup').toggle();
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $('.map-markers').removeClass('active');
        $(this).addClass('active');
      }
    } else {
      $('.hotspot-map')
        .find(`.marker-popup--mobile[data-open="${popDat}"]`)
        .toggle();
    }
  });
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.map-markers').length) {
      if (window.innerWidth > 1024) {
        $('.map-markers').removeClass('active');
      } else {
        $('.hotspot-map').find('.marker-popup--mobile').hide();
      }
    }
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }
  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });
  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   threshold: 0.2,
  //   once: true,
  // });
  ScrollOut({
    // offset: function () {
    //   return window.innerHeight - 200;
    // },
    once: false,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      // if ($target.hasClass('how-can-we-help')) {
      //   setTimeout(function () {
      //     $target.hide();
      //   }, 2000);
      // } else {
      // }
      $target.find('.popup-close-btn').on('click', function (e) {
        e.preventDefault();
        $target.hide('1000');
      });
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  // $(window)
  //   .on('toggled.zf.responsiveToggle', function () {
  //     $('.menu-icon').toggleClass('is-active');
  //   })
  //   .on('changed.zf.mediaquery', function () {
  //     $('.menu-icon').removeClass('is-active');
  //   });

  /**
   * Close responsive menu on orientation change
   */
  // $(window).on('orientationchange', function () {
  //   setTimeout(function () {
  //     if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
  //       $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
  //     }
  //   }, 200);
  // });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  if (window.innerWidth < 1025) {
    let headerHeight = $('#header').outerHeight(false);
    $('body').css('paddingTop', headerHeight + 'px');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  // animate images
  // Animate image in cards
  let animateImages = $('div[data-animate-image]');
  let topHeaight;
  if (animateImages.length) {
    let wrapperImages = animateImages.parent();
    topHeaight = wrapperImages.offset().top - wrapperImages.height();
  }
  if ($(window).scrollTop() >= topHeaight) {
    animateImages.each(function () {
      $(this).attr('data-animate-image', true).css('overflow', 'hidden');
      $(this)
        .find('[data-animate-image-wrapper]')
        .addClass('data-animate-image');
    });
  }

  if ($(window).scrollTop() <= topHeaight) {
    animateImages.each(function () {
      $(this).attr('data-animate-image', false).css('overflow', 'auto');
      $(this)
        .find('[data-animate-image-wrapper]')
        .removeClass('data-animate-image');
    });
  }

  if (window.innerWidth > 1024) {
    if ($(window).scrollTop() > 300) {
      $('#sticky-header').slideDown();
    } else {
      $('#sticky-header').slideUp(100);
    }
  }
});
$('.header-top .search__submit').on('click', function (e) {
  const $input = $(this).prev('.search__input');
  if (!$input.hasClass('show')) {
    e.preventDefault();
    $input.addClass('show');
  }
});

$(document).on('click', function (e) {
  if (
    $(e.target).parents('.search').length == 0 &&
    !$('.header-top .search__input').val()
  ) {
    $('.header-top .search__input').removeClass('show');
  }
});

$('.slider-container').each((idx, el) => {
  const arrowsContainer = $(el).parent().find('.slider-arrows').eq(0);
  let slidesAmount = $(el).hasClass('webinar-slider-container') ? 3 : 2;
  $(el).slick({
    slidesToShow: slidesAmount,
    rows: 0,
    appendArrows: arrowsContainer,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});

$(window).on('load changed.zf.mediaquery', function () {
  if (Foundation.MediaQuery.is('medium down')) {
    $('.testimonials-slider').each((i, el) => {
      if ($(el).hasClass('slick-initialized')) return;

      $(el).slick({
        arrows: false,
        rows: 0,
        slidesToShow: 1,
        dots: true,
      });
    });
  } else {
    if ($('.testimonials-slider').hasClass('slick-initialized')) {
      $('.testimonials-slider').slick('destroy');
    }
  }
});

let $preloader = $('.preloader');
if ($preloader.length) {
  $preloader.addClass('preloader--hidden');
}

$(document).ajaxComplete(function () {
  if ($('.vczapi-pro-registration-data-result').has('a')) {
    $('.vczapi-pro-registration-data-result').html(
      'You have been successfully registered to this event. Please check your email.'
    );
  }
});
